import React,{useEffect} from 'react';
import styled from "styled-components";
import facebook from '../images/facebook.png';
import youtube from '../images/youtube.png';
import linkedin from '../images/linkedin.png';
import instagram from '../images/instagram.png';
import 'aos/dist/aos.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ContactUs from '../components/contactForm';
import fondo from '../images/benner_6.jpg';
import ScrollToTop from '../components/ScrollToTop';
import ReactGA from 'react-ga';


function ContactPage(props) {


    useEffect( () => {
        ReactGA.pageview('/contact')
    }, [])

    const ClickHandler = () =>{
        ReactGA.event({
            category: 'Button',
            action: 'click en boton de red social en pagina de contacto'
        })
    }

    return (
        <>          
            <ScrollToTop />
            <Navbar />
            <Base id='contact'>
                <Titulo>
                    <hr/>
                    <h1>Contacto</h1>
                    <hr/>
                </Titulo>
                <p>
                    Si quieres saber más o comunicarte con nosotros, pueden encontrarnos en nuestras redes sociales.
                </p>
                <div className="social-media" data-aos="fade-up" >
                    <div className="facebook-post">
                        <iframe title='blankpoint-facebook-post' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FBlankPointFS&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="500" style={{border:'none', overflow:'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                    </div>
                    <div className="rrss-links">
                        <Media>                    
                            <a onClick={ClickHandler} href='https://www.facebook.com/BlankPointFS' target="_blank" rel="noreferrer noopener">
                                <img src={facebook} alt=''/>
                                <p className='rrss-text'>Facebook</p>
                            </a>
                        </Media>
                        <Media>                    
                            <a onClick={ClickHandler} href='https://www.instagram.com/puntovacio.fs/' target="_blank" rel="noreferrer noopener">
                                <img src={instagram} alt=''/>
                                <p className='rrss-text'>Instagram</p>
                            </a>
                        </Media>
                        <Media>                    
                            <a onClick={ClickHandler} href='https://www.linkedin.com/company/blankpoint/' target="_blank" rel="noreferrer noopener">
                                <img src={linkedin} alt=''/>
                                <p className='rrss-text'>Linkedin</p>
                            </a>
                        </Media>
                        <Media>                    
                            <a onClick={ClickHandler} href='https://www.youtube.com/channel/UC99RZmxZu_wVIGeVr4ACq7g' target="_blank" rel="noreferrer noopener">
                                <img src={youtube} alt=''/>
                                <p className='rrss-text'>Youtube</p>
                            </a>
                        </Media>
                    </div>
                </div>
                <Titulo>
                    <hr/>
                    <h1>Escríbenos</h1>
                    <hr/>
                </Titulo>
                <div className="contact-form">
                    <ContactUs/>

                </div>
            </Base>
            <Footer />
        </>
    );
}

export default ContactPage;

const Titulo = styled.div`
    width:80%;
    padding-top:80px;
    padding-bottom:10px;
    margin:auto;
    display:grid;
    grid-template-columns:25% auto 25%;
    text-align:center;
    @media screen and (max-width: 1000px) {
        width:95%;
    }
    h1{
        margin-left:30px;
        margin-right:30px;
        padding-top:10px;
        padding-bottom:10px;
        font-family: 'Poppins', sans-serif;
        font-weight:300;
    }
`

const Media = styled.div`
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius:20px;
    margin-left:20%;
    margin-right:20%;
    
    a{
        text-decoration:none;
        display:grid;
        grid-template-columns:25% auto;
        
 
    }
    img{
        display:block;
        margin:auto;
        width:90%;

    }    
    &:hover{
        
        .rrss-text{
            text-decoration: underline;
            text-decoration-color: #00000073;
        }
        
        @media screen and (max-width: 960px) {
            .rrss-text{
                text-decoration: none;
            }
            
            background-color: #669495;
            transition: 0.2s all ease;
        }
    }
    


`;

const Base = styled.div`
    background-image: url(${fondo});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    .contact-form{
        margin: auto;
        width:50%;
        padding-bottom: 30px;
        @media screen and (max-width: 1200px) {
            width:80%;
        }
        @media screen and (max-width: 700px) {
            width:100%;
            margin:0
        }
    }
    p{        
        font-family: 'Poppins', sans-serif;
        font-weight:300;
        font-size:25px;
        text-align:center;
        color:#3B413A;
    }
    .social-media{
        width:50%;
        margin:auto;
        display:grid;
        grid-template-columns: 50% 50%;
        margin-top:50px;
        @media screen and (max-width: 1200px) {
            width:80%;
        }
        @media screen and (max-width: 750px) {
            width:95%;
        }
        @media screen and (max-width: 640px) {
            display: block;
            iframe{
                display:block;
                margin:auto;
            }           
        }
        
        .rrss-text{
            margin-top:10px;
            font-size:25px;
            text-align:center;
            font-family: 'Poppins', sans-serif;
            font-weight:300;
            color: #000000;
                    
        }
        

    }
`;