import React,{useEffect}  from 'react';
import styled from "styled-components";
import logo from '../images/blankpointlogo.png';
import protesta from '../images/protesta.jpg';
import sesgo from '../images/sesgo.png';
import ia from '../images/inteligencia-artificial.png';
import news from '../images/noticias.png';
import 'aos/dist/aos.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import fondo from '../images/benner_6.jpg';
import ScrollToTop from '../components/ScrollToTop';
import ReactGA from 'react-ga';


function AboutPage(props) {
    useEffect( () => {
        ReactGA.pageview('/about')
    }, [])
    return (
        <>
            <ScrollToTop />
            <Navbar />
            <About>
                <div className="problem-container"  id='problem'>
                    <div className="title" >
                        <hr/>
                        <h1>El problema del sesgo ideológico</h1>
                        <hr/>
                    </div>
                    <div className="problem-context">
                        <p>
                            El sesgo de los medios masivos de comunicación es algo latente y que cada vez toma más fuerza a medida que la política se hace relevante en el nuevo contexto social que vivimos hoy en día. Diversos estudios tratan cómo el exceso de sesgo político puede sesgar incluso los hechos en pos de un fin político.
                        </p>
                        <img src={protesta} alt=''/>                    
                        <p>
                            Los medios de comunicación con un marcado sesgo ideológico suelen omitir ciertos aspectos de una noticia, o la redactan de una manera que se adecue a su relato. El ciudadano común no está al pendiente de estas sutilezas, por lo que es fácilmente influenciable por el sesgo al que estuvo expuesto, logrando imponer una idea sesgada sobre el hecho noticioso.
                        </p>
                        <p className='pc-final' data-aos="zoom-in" data-aos-easing="ease-in-out-back" data-aos-duration="500">
                            Esta problemática a motivado la creación de BlankPoint.  
                        </p>
                        
                    </div>
                </div>
                <div className="solution-container" id='about'>
                    <div className="title-logo">                    
                        <hr/>
                        <img src={logo} alt='' data-aos="zoom-in"  />
                        <hr/>
                    </div>
                    <div className="title" data-aos="slide-down" >
                        <h1>¿ Qué es BlankPoint ?</h1>                    
                    </div>
                    <div className="solution-context">
                        <p>
                            BlankPoint es una aplicación y extensión Web que detectará el sesgo político en los medios de comunicación. No será la monótona discusión de si se pertenece a la izquierda o derecha. Para llevar esto a cabo habrá un motor interno que analizará y etiquetará las noticias en sus sesgos, calculará el nivel de confianza del medio/página que emitió la noticia como así también la tendencia del periodista que la emitió.
                        </p>
                    </div>
                    <Features>
                        <div className="feature-card" >
                            <div className="card-text">
                                <h2>
                                    <img src={sesgo} alt=''/>
                                    Sesgo Político
                                </h2>
                                <p>BlankPoint te mostrará el sesgo político de cualquier noticia que encuentres en el internet, incluyendo el sesgo del medio de comunicación y hasta el sesgo político del periodista.</p>
                            </div>
                        </div>
                        <div className="feature-card" >
                            <div className="card-text">
                                <h2>
                                    <img src={ia} alt=''/>
                                    Inteligencia Artificial
                                </h2>
                                <p>BlankPoint cuenta con varios modelos de inteligencia artificial para su análisis de sesgo, los cuales se retroalimentan con la data de nuestros usuarios.</p>
                            </div>
                        </div>
                        <div className="feature-card">
                            <div className="card-text">
                                <h2>
                                    <img src={news} alt=''/>
                                    Distintos puntos de vista
                                </h2>
                                <p>BlankPoint te mostrará la misma noticia redactada por diferentes medios de comunicación en base a su sesgo ideológico, de esta manera podrás tener una perspectiva global sobre la noticia.</p>
                            </div>
                        </div>                    
                    </Features>                
                </div>
                <div className='solution-context-2' >                    
                    <p>
                    De esta forma nuestros usuarios sabrán frente a qué sesgo se encuentran y qué tan confiable es la noticia, todo esto será mostrado de forma sencilla e icónica. La forma en que una persona puede ver esto será mediante una extensión web que le dirá al usuario todos estos datos y podrá ver todas las noticias analizadas en nuestra aplicación web.
                    </p>
                </div>
            </About>
            <Footer />
        </>
    );
}

export default AboutPage;

const Features = styled.div`
    display:flex;
    justify-content: space-between;
    margin-bottom: 20px;
    
    gap: 20px;
    @media screen and (max-width: 1100px) {
        flex-wrap: wrap;
        justify-content:center;
    }
    .feature-card{
        width:400px;
        @media screen and (max-width: 860px) {
            margin:auto;
        }
        @media screen and (max-width: 500px) {
            margin:auto;
            width:95%;
        }
        
        img{
            width:100%;            
            @media screen and (max-width: 500px) {
                width:95%;
                margin:auto;
                display:block;
            }
        }
        .card-text{
            font-family: 'Poppins', sans-serif;
            font-size:20px;
            width:80%;
            background-color: white;
            padding:20px;
            margin: 0 auto;
            position:relative;
            box-shadow: 5px 10px #3b413a76;
            border: 1px solid gray;
            h2{
                align-items:center;
                display:grid;
                grid-template-columns:1fr 4fr;
                font-size:1.5rem;
                text-align:center;                
                color:#669495;
            }
            @media screen and (max-width: 500px) {
                margin:auto;
                width:99%;
            }

        }
    }

`

const About = styled.div`
    padding-top:80px;
    background-image: url(${fondo});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    background-attachment: fixed;

    .solution-container{
        width:80%;
        margin: auto;
        @media screen and (max-width: 1300px) {
            width: 95%;
        }

        h1{
            font-family: 'Poppins', sans-serif;
            font-weight:300;
            color:#3B413A;
        }

        .title-logo{
            margin-top:70px;
            display:grid;
            grid-template-columns:25% auto 25%;
            img{
                height:100px;
                display:block;
                margin:auto;
            }
            hr{
                margin-top:50px;
            }
        }
        .title{
            text-align:center;
            
            color:#3B413A;
        }
        .solution-context{     
            margin: 70px;
            @media screen and (max-width: 1000px) {
                margin-top: 70px;
                margin-bottom: 70px;
                margin-left: 10px;
                margin-right: 10px;
            }
            img{
                display: block;
                width: 70%;
                margin-left: auto;
                margin-right: auto;               
                
            }
            p{
                font-family: 'Poppins', sans-serif;
                font-size:1.3rem;
                text-align:center;
            }
        }

        
    }
    .problem-container{
        width:80%;
        margin:auto;
        .title{
            display:grid;
            grid-template-columns:25% auto 25%;
            text-align:center;
            h1{
                font-family: 'Poppins', sans-serif;
                font-weight:300;
            }
        }
        .problem-context{           
            margin: 50px;
            img{
                display: block;
                width: 50%;
                margin-left: auto;
                margin-right: auto;  
                border-radius: 25px; 
                @media screen and (max-width: 1100px) {
                    width: 95%;
                }            
                
            }
            p{
                font-family: 'Poppins', sans-serif;
                font-size:1.3rem;
                text-align:center;
            }
            .pc-final{
                padding-top:5px;
                padding-bottom:5px;
                background-color: #669495;
                border: 1px solid gray;
                color:#F7F9F4;
                font-size:30px;
            }
            @media screen and (max-width: 800px) {
                margin-top: 50px;
                margin-bottom: 50px;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        
        @media screen and (max-width: 720px) {
            width: 95%;
        }
    }
    
    .solution-context-2{     
        background-color:#669495;
        padding-left:20%;
        padding-right:20%;
        padding-top:30px;
        padding-bottom:30px;
        color: #F7F9F4;
        p{
            font-family: 'Poppins', sans-serif;
            font-size:1.3rem;
            text-align:center;
        }
        @media screen and (max-width: 1000px) {
            padding-left:5%;
            padding-right:5%;
        }
    }


`;