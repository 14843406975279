import React,{useEffect} from 'react';
import styled from "styled-components";
import preemlogo from '../images/preem_logo.png';
import mision from '../images/mision.png';
import vision from '../images/testigo.png';
import proposito from '../images/proposito.png';
import {AiFillLinkedin} from 'react-icons/ai';
import ln_edison from '../images/ln_edison.jpg';
import ln_ian from '../images/ln_ian.jpg';
import ln_ignacio from '../images/ln_ignacio.jpg';
import ln_javier from '../images/ln_javier.jpg';
import ln_jeremy from '../images/ln_jeremy.jpg';
import ln_lukas from '../images/ln_lukas.jpg';
import 'aos/dist/aos.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import fondo from '../images/benner_6.jpg';
import ScrollToTop from '../components/ScrollToTop';
import ReactGA from 'react-ga';


function DevPage(props) {

    useEffect( () => {
        ReactGA.pageview('/devpage')
    }, [])

    const ClickHandler = () =>{
        ReactGA.event({
            category: 'Button',
            action: 'click en boton de red linkedin'
        })
    }

    return (
        <>  
            <ScrollToTop />
            <Navbar />
            <Base >
                <Titulo id='preem'>
                    <hr/>
                    <h1>¿Quiénes somos?</h1>
                    <hr/>
                </Titulo>            
                
                <div className="preem-description">
                    <div className='img-container' >
                        <img src={preemlogo} alt=''  />
                    </div >                
                    <p >
                        Somos Punto Vacío, un equipo de desarrollo de software compuesta por estudiantes de Ingeniería Civil Informática de la Universidad Técnica Federico Santa María.
                    </p>
                    

                </div>
                <div className='mvp-container'>
                    <Carta data-aos="zoom-in" data-aos-offset="150">
                        <img src={mision} alt=''/>
                        <div className="title">Misión</div>
                        <p>Permitir a la población informarse sin propaganda ni manipulación de los medios, lo que es aún más relevante en una sociedad tan polarizada como lo es Chile en estos tiempos. De esta forma, se reduce el conflicto entre grupos ideológicos por medio del acceso a la información objetiva y precisa.</p>
                    </Carta>
                    <Carta data-aos="zoom-in" data-aos-offset="150">
                        <img src={vision} alt=''/>
                        <div className="title" >Visión</div>
                        <p>Transformar el negocio de las noticias sensacionalistas, dando paso a una sociedad informada sin conflictos provocados por los medios que buscan obtener visitas a través de engaños y control emocional del público.</p>
                    </Carta>
                    <Carta data-aos="zoom-in" data-aos-offset="150">
                        <img src={proposito} alt=''/>
                        <div className="title">Propósito</div>
                        <p>Destruir las barreras de pensamiento que imponen las ideologías políticas dentro de los medios de comunicación en nuestra era.</p>
                    </Carta>

                </div>
                <Titulo id='dev'>
                    <hr/>
                    <h1>Nuestro Equipo</h1>
                    <hr/>
                </Titulo>
                <div className="dev-container">
                    
                    <div className="linkedin-container">
                        <LkCard  >
                            <img src={ln_ignacio} alt='' />
                            <div className="name">Ignacio Oñate</div>
                            <div className="rol">Scrum Master</div>
                            <div className="contact">
                                <a onClick={ClickHandler} href="https://www.linkedin.com/in/ignacio-onate-lopez/" >
                                    <AiFillLinkedin />
                                </a>
                            </div>
                        </LkCard>
                        <LkCard  >
                            <img src={ln_lukas} alt='' />
                            <div className="name">Lukas Gutiérrez</div>
                            <div className="rol">Product Owner</div>
                            <div className="contact">
                                <a onClick={ClickHandler} href="https://www.linkedin.com/in/lukas-gutierrez-lisboa-081041156/" >
                                    <AiFillLinkedin />
                                </a>
                            </div>
                        </LkCard>
                        <LkCard  >
                            <img src={ln_jeremy} alt=''/>
                            <div className="name">Jeremy Molina</div>
                            <div className="rol">Marketing</div>
                            <div className="contact">
                                <a onClick={ClickHandler} href="https://www.linkedin.com/in/ignacio-onate-lopez/" >
                                    <AiFillLinkedin />
                                </a>
                            </div>
                        </LkCard>
                        <LkCard  >
                            <img src={ln_javier} alt='' />
                            <div className="name">Javier Vargas</div>
                            <div className="rol">Frontend developer</div>
                            <div className="contact">
                                <a onClick={ClickHandler} href="https://www.linkedin.com/in/javier-vargas-montesinos/" >
                                    <AiFillLinkedin />
                                </a>
                            </div>
                        </LkCard>
                        <LkCard  >
                            <img src={ln_edison} alt=''/>
                            <div className="name">Edison Vargas</div>
                            <div className="rol">Backend developer</div>
                            <div className="contact">
                                <a onClick={ClickHandler} href="https://www.linkedin.com/in/ignacio-onate-lopez/" >
                                    <AiFillLinkedin />
                                </a>
                            </div>
                        </LkCard>
                        
                        <LkCard >
                            <img src={ln_ian} alt='' />
                            <div className="name">Ian Pérez</div>
                            <div className="rol">Backend developer</div>
                            <div className="contact">
                                <a onClick={ClickHandler} href="https://www.linkedin.com/in/ian-perez-santis/" >
                                    <AiFillLinkedin />
                                </a>
                            </div>
                            
                        </LkCard>
                    </div>
                </div>
            </Base>
            <Footer />
        </>
    );
}

export default DevPage;

const Titulo = styled.div`
    width:80%;
    padding-top:80px;
    padding-bottom:40px;
    margin:auto;
    display:grid;
    grid-template-columns:25% auto 25%;
    text-align:center;    
    color:#3B413A;
    @media screen and (max-width: 1000px) {
        width:95%;
    }
    h1{
        margin-left:30px;
        margin-right:30px;
        padding-top:10px;
        padding-bottom:10px;
        font-family: 'Poppins', sans-serif;
        font-weight:300;
    }
`

const LkCard = styled.div`
    margin: 10px;
    img{
        width: 70%;
        display: block;
        margin: auto;

    }
    .name{
        font-family: 'Poppins', sans-serif;
        font-weight:300;
        text-align:center;
        font-size:23px;
    }
    .rol{
        text-align:center;
        color:#669495;
    }
    .contact{
        text-align:center;
        font-size: 30px;
        @media screen and (max-width: 1000px) {
            font-size: 50px;
        }
    }


`



const Base = styled.div`

    background-image: url(${fondo});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    background-attachment: fixed;
    .dev-container{
        width:60%;
        margin: auto;
        .linkedin-container{
            display:grid;
            grid-template-columns:33% 33% 33%;
            @media screen and (max-width: 700px) {
                grid-template-columns:50% 50%;
            }
            @media screen and (max-width: 500px) {
                grid-template-columns:100%;
            }
        }
        @media screen and (max-width: 1150px) {
            width:90%;
        }

    }


    .mvp-container{
        width:80%;
        margin-top:20px;
        margin-left:auto;
        margin-right:auto;
        display:grid;
        grid-template-columns: 33% 33% 33%;
        @media screen and (max-width: 1300px) {
            width:95%;
        }
        @media screen and (max-width: 1150px) {
            display: block;
        }
    }
    .preem-description{
        margin-left:15%;
        margin-right:15%;
        margin-top:30px;
        margin-bottom:70px;
        display:grid;
        grid-template-columns:25% auto;
        p{
            margin:10px;
            font-family: 'Poppins', sans-serif;
            font-size:20px;
        }

        .img-container{
            display:flex;
            justify-content: center;            
            align-items: center;
            img{                
                width:99%;
                border-radius: 10px;
            }
        }


        @media screen and (max-width: 1250px) {
            margin-left:5%;
            margin-right:5%;
        }
        @media screen and (max-width: 1000px) {
            display: block;

            .img-container{
                img{
                    width:300px;
                    margin-bottom:50px;
                }
            }
            p{
                text-align:center;
            }
        }
    }

`;

const Carta = styled.div`

    margin:10px;
    padding:20px;
    border: 1px solid gray;
    background-color:white;
    img{
        position: absolute;
        width:40px;
    }
    .title{
        font-family: 'Poppins', sans-serif;
        font-weight:300;
        font-size:30px;
        text-align:center; 
        color:#669495;
    }
    p{
        margin:10px;
        font-family: 'Poppins', sans-serif;
        font-size:20px;
    }
    @media screen and (max-width: 1150px) {
        margin:auto;
        width:80%;
        margin-top:10px;
    }
    @media screen and (max-width: 560px) {
        
        width:95%;
    }

`;



