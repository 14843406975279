import React,{useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import styled from "styled-components";
import ferialogo from '../images/fesw-logo.png';
import usm_di from '../images/logo_DI.png';
import preem_logo from '../images/preem_logo.png';
import {
    AiOutlineFacebook,
    AiOutlineInstagram,
    AiOutlineYoutube,
    AiOutlineLinkedin} from 'react-icons/ai';
import ReactGA from 'react-ga';

function Footer(props) {

    useEffect( () => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    const ClickHandler1 = () =>{
        ReactGA.event({
            category: 'Button',
            action: 'click en boton de navegacion en footer'
        })
    }
    const ClickHandler2 = () =>{
        ReactGA.event({
            category: 'Button',
            action: 'click en boton de red social en footer'
        })
    }


    return (
        <>
            <Base>                
                <div className='navegacion'>
                    <h3>Navegación</h3>
                    <ul>
                        <li>
                            <NavLink onClick={ClickHandler1} className='link' to='/'>
                                Home
                            </NavLink> 
                        </li>
                        <li>
                            
                            <NavLink onClick={ClickHandler1} className='link' to='/AboutPage'>
                                ¿Qué es BlankPoint?
                            </NavLink> 
                        </li>
                        <li>
                            <NavLink onClick={ClickHandler1} className='link' to='/DevPage'>
                                Nosotros
                            </NavLink>                             
                        </li>
                        <li>
                            <NavLink onClick={ClickHandler1} className='link' to='/Contact'>
                                Contacto
                            </NavLink>                             
                        </li>
                    </ul>
                </div>
                <div className="redes-sociales">
                    <h3>Contacto</h3>
                        <ul>
                            <li>
                                <a onClick={ClickHandler2} className='rrss' href='https://www.facebook.com/BlankPointFS' target="_blank" rel="noreferrer noopener">
                                    <div className='logo'>
                                        <AiOutlineFacebook />
                                    </div> 
                                    <div className='texto'>Facebook</div>
                                </a>                            
                            </li>
                            <li>
                                <a onClick={ClickHandler2} className='rrss' href='https://www.instagram.com/puntovacio.fs/' target="_blank" rel="noreferrer noopener">
                                    <div className='logo'>
                                        <AiOutlineInstagram />
                                    </div> 
                                    <div className='texto'>Instagram</div>
                                </a> 
                                
                            </li>
                            <li>
                                <a onClick={ClickHandler2} className='rrss' href='https://www.linkedin.com/company/blankpoint/' target="_blank" rel="noreferrer noopener">
                                    <div className='logo'>
                                        <AiOutlineLinkedin />
                                    </div> 
                                    <div className='texto'>Linkedin</div>
                                </a>
                            </li>
                            <li>
                                <a onClick={ClickHandler2} className='rrss' href='https://www.youtube.com/channel/UC99RZmxZu_wVIGeVr4ACq7g' target="_blank" rel="noreferrer noopener">
                                    <div className='logo'>
                                        <AiOutlineYoutube />
                                    </div> 
                                    <div className='texto'>Youtube</div>
                                </a>
                            </li>
                        </ul>
                </div>
                <div className='creditos'>
                    <h3>Créditos</h3>   
                    <div className='lista'>               
                        <a href="https://www.flaticon.es/iconos-gratis/youtube" title="youtube iconos" target="_blank" rel="noreferrer noopener">Youtube icon Md Tanvirul Haque - Flaticon</a>
                        <a href="https://www.flaticon.es/iconos-gratis/linkedin" title="linkedin iconos" target="_blank" rel="noreferrer noopener">Linkedin icon riajulislam - Flaticon</a>
                        <a href="https://www.flaticon.es/iconos-gratis/instagram" title="instagram iconos" target="_blank" rel="noreferrer noopener">Instagram icono Laisa Islam Ani - Flaticon</a>
                        <a href="https://www.flaticon.es/iconos-gratis/facebook" title="facebook iconos" target="_blank" rel="noreferrer noopener">Facebook icon Pixel perfect - Flaticon</a>
                        <a href="https://www.freepik.es/foto-gratis/negocio-tecnologia-globo-fondo-pantalla-degradado_13312403.htm#query=Global&position=22&from_view=search" target="_blank" rel="noreferrer noopener">Imagen de rawpixel.com</a>
                        <a href="https://www.flaticon.es/iconos-gratis/camino" title="camino iconos" target="_blank" rel="noreferrer noopener">Camino icon geotatah - Flaticon</a>
                        <a href="https://www.flaticon.es/iconos-gratis/ojo" title="ojo iconos" target="_blank" rel="noreferrer noopener">Ojo icon Freepik - Flaticon</a>
                        <a href="https://www.flaticon.es/iconos-gratis/mision" title="misión iconos" target="_blank" rel="noreferrer noopener">Misión icon deemakdaksina - Flaticon</a>
                        <a href="https://www.flaticon.es/iconos-gratis/noticias" title="noticias iconos" target="_blank" rel="noreferrer noopener">Noticias icon Freepik - Flaticon</a>
                        <a href="https://www.flaticon.es/iconos-gratis/inteligencia-artificial" title="inteligencia artificial iconos" target="_blank" rel="noreferrer noopener">IA icon Freepik - Flaticon</a>
                        <a href="https://www.flaticon.es/iconos-gratis/politica" title="política iconos" target="_blank" rel="noreferrer noopener">Política icon Flat Icons - Flaticon</a>
                        <a href="https://www.freepik.es/vector-gratis/equipos-opuestos-activistas-pancartas-si-o-no_30534343.htm#page=4&query=conflict&position=34&from_view=search" target="_blank" rel="noreferrer noopener">Imagen de upklyak</a>
                        <a href="https://www.freepik.es/vector-gratis/diseno-fondo-abstracto-blanco_30080187.htm#page=7&query=background&position=9&from_view=search">Imagen de bunny</a>
                    </div> 
                </div>
            </Base>
            <Logos>
                <img className='preem' src={preem_logo} alt=''/>
                <img className='di' src={usm_di} alt=''/>
                <img className='feria' src={ferialogo} alt=''/>
            </Logos>
            <Copyright>
                Copyright © 2022 BlankPoint - Feria de Software UTFSM
            </Copyright>
        </>
    );
}

export default Footer;

const Logos = styled.div`
    width: 100%;
    background-color:#041511;
    text-align:center;
    img{
        height:50px;
        padding-left:40px;
        padding-right:40px;
        @media screen and (max-width: 450px) {    
            height:100%;        
            width:90%;
        }
    }
`

const Copyright = styled.div`
    width: 100%;
    background-color:white;
    height: 30px;
    text-align:center;
`

const Base = styled.div`
  padding-top:10px;
  padding-left: 10%;
  padding-right: 10%;
  background-color:#041511;
  font-family: 'Poppins', sans-serif;
  display: grid;
  grid-template-columns: 33% 33% 33%;  
  @media screen and (max-width: 1200px) {
    grid-template-columns: 50% 50%;  
    padding-left: 5%;
    padding-right: 5%;
  }
  @media screen and (max-width: 720px) {
    grid-template-columns: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .creditos{
    color: #F7F9F4;
    .lista{
        margin-left:30px;     
        overflow-y: scroll;
        height: 200px;

        
        a{
            text-decoration:none;
            display:block;
            margin: 10px 0;
            font-size:14px;
            color:#669495;
            &:hover{
                color:orange;
            }
        }
    }

  }
  .navegacion{
    margin:10px;
    width:90%;
    
    
    .link{
        text-decoration:none;
        color:#669495;
        cursor:pointer;
        &:hover{
            color:orange;
        }
        transition: 0.3s all ease;
    }
    
    h3{
        width:90%;
        
        color: #F7F9F4;
    }
  }
  .logo-empresa{
    margin:10px;
    display: flex;
    justify-content: center;
    img{
        display:block;
        margin: auto;
        width:90%;
    }

  }
  .redes-sociales{
    margin:10px;
    color:white;
    font-family: 'Poppins', sans-serif;
    font-weight:300;
    
    li{
        list-style-type: none;
        
    }
    .rrss{
        text-decoration:none;
        display: flex;
        align-items: center;
        color:#669495;
        .logo{
            position: relative;
            font-size:20px;
            margin-right:5px;
            top: -2px;
            
            
        }
        &:hover{
            color:orange;
        }
    }
  }
  .creditos{
    margin:10px;
    font-family: 'Poppins', sans-serif;
    font-weight:300;
  }



`;