import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import DevPage from './pages/DevPage';
import Contact from './pages/ContactPage';
import {useEffect} from 'react';
import ReactGA from 'react-ga';
import Aos from 'aos';
import 'aos/dist/aos.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

const TRACKING_ID = 'UA-244721174-2';
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect( () => {   
    console.log('--GA--');
    Aos.init({
      delay: 200,
    });
  }, [])

  return (
    <Router>
      <Routes>        
        <Route  path='/' element={<HomePage/>}/>
        <Route  path='/AboutPage' element={<AboutPage/>}/>
        <Route  path='/DevPage' element={<DevPage/>}/>
        <Route  path='/Contact' element={<Contact/>}/>
      </Routes>
    </Router>
  );
}
export default App;

